<template>
  <v-dialog v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="calc.fine_comment">
        <span v-bind="attrs" v-on="on" @click="descDialog = true" class="black--text caption font-italic" :class="$vuetify.breakpoint.xs ? 'pr-2' : ''">
          {{ (calc.fine_comment).slice(0, 30) }}
        </span>
      </div>
      <div v-else>
        <v-chip :class="$vuetify.breakpoint.xs ? 'mr-2' : ''" v-bind="attrs" v-on="on" small color="primary" outlined @click="dialog = true">
          <span class="text-body-2">Add</span>
        </v-chip>
      </div>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar dark dense color="primary">
        <v-btn class="mr-1 row no-gutters justify-end" icon>
          <v-icon @click="dialog = false" large>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-textarea solo
                      hide-details
                      label="Введите текст"
                      outlined
                      rounded
                      v-model="item.fine_comment" />
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-space-around pb-3">
        <v-btn @click="save" color="primary" elevation="3" rounded outlined>
          Сохранить
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import {uuid} from "uuidv4";

export default {
  props: {
    calc: Object,
  },
  data() {
    return {
      item:{
        id: uuid.Nil,
        fine_comment: ''
      },
      dialog: false
    }
  },

  mounted() {
    this.calc.id = this.item.id;
    if (this.calc.fine_comment) {
      this.item.fine_comment = this.calc.fine_comment;
    }
  },
  methods: {
    save() {
      this.calc.fine_comment = this.item.fine_comment;
      this.dialog = false;
    }
  }
}
</script>
