<template>
  <v-row no-gutters :class="$vuetify.breakpoint.xs ? 'px-4' : ''" class="grey lighten-4 mx-1 mb-1 py-2">
    <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 justify-center d-flex align-center">
      <div class="py-3">
        <div class="row justify-center font-weight-bold text-body-2 text-center">{{ driver.name }}</div>
        <div class="row justify-center caption">{{ uniqueVehicles }}</div>
        <div class="row justify-center caption font-weight-bold">{{ driver.duration }}</div>
      </div>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Транспорт:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center align-center">
      <v-autocomplete v-model="selectedV"
                      :items="vehicleTypes"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      rounded
                      class="px-1"
                      @change="calculate"
                      label="Транспорт"
                      hide-details
                      required/>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Дл. (в ч.):</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-text-field v-model="workHours"
                    class="px-1"
                    dense
                    outlined
                    hide-details
                    rounded
                    @change="calculate"
                    required/>
    </v-col>
    <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters">
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Заказы:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-6 col-md-6 col-sm-6 justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold text-body-2">{{ driver.orders_count }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Бонусы:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-6 col-md-6 col-sm-6 justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold text-body-2">{{ calc.bonus_count }}</span>
      </v-col>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Штраф:</span>
    </v-col>
    <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-text-field v-model="fine"
                    class="px-1"
                    dense
                    outlined
                    rounded
                    hide-details
                    @change="calculate"
                    required/>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span v-if="selectedV && workHours" :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Комментарий:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <comment v-if="selectedV && workHours" :calc="calc" :calculate="calculate"/>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Аванс:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-text-field v-model="prepaid"
                    class="px-1"
                    dense
                    outlined
                    hide-details
                    @change="calculate"
                    rounded
                    required/>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span class="caption">Итого З.П.:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold text-body-2">{{ (calc.bonus_count * calc.bonus_payment) + (workHours * calc.hours_payment) - (fine) }} с.</span>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span class="caption">Без. нал.:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-if="calc.cashless_store" class="font-weight-bold text-body-2">{{ calc.cashless_store }} с.</span>
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-else class="font-weight-bold text-body-2">-</span>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span v-if="selectedV && workHours" :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Подробно:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <info v-if="selectedV && workHours" :driver="driver" :vehicle="selectedV" :rule="rule" :calc="calc"/>
    </v-col>
  </v-row>
</template>

<script>

import info from './shift.vue';
import comment from './comment.vue';

export default {
  components: {info, comment},
  props: {
    driver: Object
  },
  data() {
    return {
      prepaid: 0,
      workHours: 0,
      fine: 0,
      selectedV: {},
      calc: {
        bonus_count: 0,
        bonus_payment: 0,
        hours_payment: 0,
      }
    }
  },

  mounted() {
    this.$store.dispatch("calculation/fetchVehicle");
    this.$store.dispatch("calculation/fetchRules");
  },

  computed: {
    uniqueVehicles() {
      let result = "";
      let list = this.driver.vehicles.filter((v, i, a) => a.indexOf(v) === i);
      list.forEach(s => {
        result += s + " "
      })
      return result;
    },
    vehicleTypes() {
      return this.$store.getters["calculation/vehicleTypes"];
    },
    rules() {
      return this.$store.getters["calculation/rules"];
    },
    rule() {
      return this.rules.find(r => r.vehicle_type_id === this.selectedV.id && r.hours_count === parseInt(this.workHours));

    }
  },

  methods: {
    calculate() {
      this.calc.driver_id = this.driver.id;
      this.calc.vehicle_type_id = this.selectedV.id;
      this.calc.orders_count = this.driver.orders_count;
      this.calc.bonus_count = this.driver.orders_count > this.rule.bonus_min_count ? this.driver.orders_count - this.rule.bonus_min_count : 0;
      this.calc.bonus_payment = this.rule.bonus_payment;
      this.calc.hours_count = this.rule.hours_count;
      this.calc.hours_payment = this.rule.hours_payment;
      this.calc.fuel_payment = this.rule.fuel;
      this.calc.cashless_store = parseInt((this.driver.paybox_store_price / 100).toFixed(1));
      this.calc.closed_at = this.driver.ended_at;
      this.calc.fine = parseInt(this.fine);
      this.calc.prepaid = parseInt(this.prepaid);
    }
  },
}
</script>
