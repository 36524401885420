<template>
  <div class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="date"
                       scrollable
                       @input="modal = false;
                       $refs.dialog.save(date);
                       fetch();
                       fetchClosed();">
        </v-date-picker>
      </v-dialog>
      <v-toolbar-title>{{ date }}</v-toolbar-title>
    </v-toolbar>
    <v-card v-if="filteredList.length" class="my-5 pb-1">
      <v-row dense class="mt-4 mx-2 hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Курьер</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Тип транспорта</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption text-no-wrap">Дл. (в ч.)</span>
        </v-col>
        <v-col cols="1" class="row no-gutters">
          <v-col cols="6" class="justify-center align-center d-flex">
            <span class="caption text-no-wrap">Зак.</span>
          </v-col>
          <v-col cols="6" class="justify-center align-center d-flex">
            <span class="caption">Бон.</span>
          </v-col>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Штрафы</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Коммент.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Аванс</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Итого ЗП</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Без. нал.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Детально</span>
        </v-col>
      </v-row>
      <div v-for="driver in filteredList" :key="driver.id">
        <driver :driver="driver"/>
      </div>
    </v-card>
    <v-card flat v-else>
      <v-row class="justify-center mt-10">
        <span class="headline">Нет записей</span>
      </v-row>
    </v-card>
  </div>
</template>

<script>

import driver from "./list.vue";
import {format} from "date-fns";

export default {
  components: {driver},
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    dialog: false
  }),

  created() {
    this.fetch();
    this.fetchClosed();
  },

  computed: {
    list() {
      return this.$store.getters["calculation/list"]
    },
    closedList () {
      return this.$store.getters["calculation/closedList"]
    },
    filteredList() {
      let filtered = [];

      this.list.forEach(driver => {
        let exists = false;
        this.closedList.forEach(c => {
          if (driver.id === c.driver_id) {
            exists = true;
          }
        })
        if (!exists) {
          filtered.push(driver);
        }
      })

      return filtered;
    }
  },

  methods: {
    fetch() {
      let payload = format(new Date (this.date),"yyyy-MM-dd");
      this.$store.dispatch("calculation/fetch", payload);
    },
    fetchClosed() {
      let payload = format(new Date (this.date),"yyyy-MM-dd");
      this.$store.dispatch("calculation/fetchClosed", payload);
    }
  }
}
</script>
